import { Component, ReactNode } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface ScrollToTopProps extends RouteComponentProps<{}> {
    children: ReactNode | ReactNode[];
}

class ScrollToTop extends Component<ScrollToTopProps> {
    componentDidUpdate(prevProps: ScrollToTopProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);