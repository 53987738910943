import React from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';

import { ReactComponent as Facebook } from '../Icons/facebook.svg';
import { ReactComponent as Instagram } from '../Icons/instagram.svg';
import { ReactComponent as Twitter } from '../Icons/twitter.svg';
import { ReactComponent as Linkedin } from '../Icons/linkedin.svg';


export default function Social() {
    return (
        <FirestoreCollection path="/social/">
            {data => {
                if (data.isLoading) {
                    return;
                }
                const social = data.value[0];
                return (
                    <>
                        {social.facebook && <a href={social.facebook} target="_blank" rel="noopener noreferrer"><Facebook /></a>}
                        {social.instagram && <a href={social.instagram} target="_blank" rel="noopener noreferrer"><Instagram /></a>}
                        {social.twitter && <a href={social.twitter} target="_blank" rel="noopener noreferrer"><Twitter /></a>}
                        {social.linkedin && <a href={social.linkedin} target="_blank" rel="noopener noreferrer"><Linkedin /></a>}
                    </>
                )
            }}
        </ FirestoreCollection>
    )
}